import React from "react"
import PropTypes from "prop-types"
import { Map, TileLayer, Marker, Popup } from "react-leaflet"
import L from "leaflet"
import "./leaflet.css"
import iconImage from "../images/icon.png"

class LocationMap extends React.Component {
  static propTypes = {
    position: PropTypes.array,
    zoom: PropTypes.number,
    markerText: PropTypes.string,
  }

  static defaultProps = {
    position: [34.7601253, -84.9687393],
    zoom: 15,
    markerText: "Big's Axe Throwing",
  }

  render() {
    var icon = L.icon({
      iconUrl: iconImage,
      iconSize: [64, 70],
    })
    let mapUrl = `https://api.mapbox.com/styles/v1/esparkman/ckdjojvvq0ddx1io7xshsorc7/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.GATSBY_MAPBOX_API_KEY}`
    return (
      <Map center={this.props.position} zoom={this.props.zoom}>
        <TileLayer
          url={mapUrl}
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
        />
        {this.props.markerText !== "" && (
          <Marker position={this.props.position} icon={icon}>
            <Popup>{this.props.markerText}</Popup>
          </Marker>
        )}
      </Map>
    )
  }
}

export default LocationMap
