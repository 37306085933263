import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Layout } from "../components"
import LocationMap from "../components/LocationMap"

const Location = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "2vets_coin.jpg" }) {
        publicURL
      }
    }
  `)

  if (typeof window !== "undefined") {
    return (
      <Layout>
        <div>
          <LocationMap />
        </div>
        <div className="mt-8 mx-8">
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="prose text-lg font-bold sm:px-6">
              <h3 className="border-gray-600 border-b-2">
                Directions from Atlanta
              </h3>
              <ol>                
                <li>
                  Follow I-75 N to GA-52 E/W Walnut Ave in Dalton. Take exit 333 from I-75 N
                </li>
                <li>Continue on W Walnut Ave to your destination in Dalton (2.2 miles)</li>
              </ol>
            </div>
            <div id="the-mill" className="prose text-lg">
              <h3 className="border-gray-600 border-b-2">
                Where are we located?
              </h3>
              <p>
                We are conveniently located at Bryman's Plaza (by the Office Depot) on Walnut Avenue.  101 W Walnut Ave Suite 17 Dalton, GA 30721
              </p>
              <img src={data.logo.publicURL} alt="The Mill at Crown Garden" />
            </div>
            <div className="prose text-lg font-bold sm:px-6">
              <h3 className="border-gray-600 border-b-2">
                Directions from Chattanooga
              </h3>
              <ol>
                <li>
                  Follow I-24 E and I-75 S to GA-52 E/W Walnut Ave in Dalton. Take exit 333 from I-75 S
                </li>
                <li>Continue on W Walnut Ave to your destination in Dalton (2.2 miles)</li>
              </ol>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
  return null
}

export default Location
